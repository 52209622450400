export default function () {
  const connectedFormInputEls = document.querySelectorAll(
    '[data-field-handle="connectedFormHandle"] input',
    '[data-field-handle="connectedFormHandle"] select',
  );

  const connectedFormInputs = new Map();

  connectedFormInputEls.forEach((inputEl) => {
    const form = inputEl.closest('form');

    const formInputEls = form.querySelectorAll('select, input:not([type=hidden])');

    formInputEls.forEach((input) => {
      const inputGroupEl = input.closest('.input-group');
      const inputFieldHandle = inputGroupEl.dataset.fieldHandle;

      const inputGroupSelector = `.js-connection-field[data-field-handle=${inputFieldHandle}]`;

      const connectedInputEl = document.querySelector(
        `${inputGroupSelector} input, ${inputGroupSelector} select`,
      );

      if (connectedInputEl) {
        input.addEventListener('change', (event) => {
          connectedInputEl.value = event.target.value;
        });
      }

      connectedFormInputs.set(inputFieldHandle, input);
    });

    form.addEventListener('onFormieSubmit', () => {
      const hashOrUrlToRedirect = inputEl.value;
      const formToScrollToEl = document.querySelector(`form[data-handle="${hashOrUrlToRedirect}"]`);

      if (hashOrUrlToRedirect && form.checkValidity()) {
        if (formToScrollToEl) {
          formToScrollToEl.scrollIntoView({ behavior: 'smooth' });
          return;
        }

        const url = new URL(hashOrUrlToRedirect);

        connectedFormInputs.forEach((input, key) => {
          url.searchParams.append(key, input.value);
        });

        window.location = url.href;
      }
    });
  });
}
